import { graphql, useStaticQuery } from 'gatsby';

const query = graphql`
  query {
    allBlogPost(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          path
          frontmatter {
            title
            date(formatString: "DD. MMMM YYYY", locale: "en")
            slug
            featuredImage {
              publicURL
            }
            featuredImageAlt
          }
          parent {
            ... on Mdx {
              body
              excerpt
              timeToRead
            }
          }
        }
      }
    }
  }
`;

export const useAllBlogPosts = () => {
  const data = useStaticQuery(query);
  return data.allBlogPost.edges.map(edge => edge.node);
};

export const useLatestBlogPosts = limit => {
  const allBlogPosts = useAllBlogPosts();
  return allBlogPosts.slice(0, limit);
};

export const useBlogPostByPath = path => {
  const allBlogPosts = useAllBlogPosts();
  return allBlogPosts.find(blogPost => blogPost.path === path);
};
