import React from 'react';
import { Link } from 'gatsby';

const BlogPostPreview = props => {
  const previewElements = props.blogPosts.map(blogPost => (
    <div key={blogPost.id} className="col-lg-6">
      <Link
        className="portfolio-item"
        to={blogPost.path}
        title={`Read article "${blogPost.frontmatter.title}"`}
      >
        <span className="caption">
          <span className="caption-content">
            <h3>{blogPost.frontmatter.title}</h3>
          </span>
        </span>
        <img
          className="img-fluid"
          src={blogPost.frontmatter.featuredImage.publicURL}
          alt={blogPost.frontmatter.featuredImageAlt}
        />
      </Link>
    </div>
  ));
  return <div className="row mb-5">{previewElements}</div>;
};

export default BlogPostPreview;
