import React from 'react';
import { Link } from 'gatsby';
import { useAllBlogPosts } from '../../../plugins/gatsby-theme-fgs-blog/src/hooks/use-blog-posts';
import BlogPostPreview from '../../../plugins/gatsby-theme-fgs-blog/src/components/blog-post-preview';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

export default () => {
  const allBlogPosts = useAllBlogPosts();

  return (
    <Layout>
      <SEO title="Blog | React Freelancer Switzerland" />
      <main>
        <div className="container text-left pt-5">
          <h1 className="text-dark mb-3">Blog</h1>
          <p>
            Read about <b>React development</b>, the <b>Gatsby framework</b> and
            general <b>web development news</b> in my blog.
          </p>
          <Link
            className="btn btn-xl btn-primary mr-4 mb-5"
            to="/mailing-list"
            title="Mailing list signup form"
          >
            Subscribe to new articles
          </Link>
        </div>
        <div className="container text-center">
          <BlogPostPreview blogPosts={allBlogPosts} />
        </div>
      </main>
    </Layout>
  );
};
